import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom"; // react-router-dom for navigation
import axios from "axios";
import { Avatar } from "@mui/material";
import { BiHide, BiShow } from "react-icons/bi";
import CircleLoader from "react-spinners/CircleLoader";

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [logged, setLogged] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [user, setUser] = useState({
        email: "",
        password: "",
    });
    const [loading, setLoading] = useState(false);
    const [loggedUser, setLoggedUser] = useState({
        username: "",
        avatar: "",
    });

    const navigate = useNavigate();  // useNavigate for navigation in React Router v6

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND}/user/login/`, user);
            const data = response.data;

            setLoading(false);
            if (data?.success) {
                console.log(data,'login submit data is');
                localStorage.setItem('token', data.message);
                const response = await axios.get(`${process.env.REACT_APP_BACKEND}/user/profile`, {
                    headers: {
                      Authorization: `Bearer ${data.message}`,  // Pass token in the header
                    },
                  });
                  console.log(response.data);
                  localStorage.setItem('user', JSON.stringify(response.data.data));
                if (data?.data?.initialCourse === "false") {
                    navigate("/program");
                    window.location.reload();  // To reload after redirection
                    return;
                }
                if (window.history.state?.redirect_uri) {
                    navigate(`/${window.history.state.redirect_uri}`);
                    window.location.reload();  // To reload after redirection
                } else {
                    navigate("/");
                }
            } else {
                setErrorMessage(data?.message);
            }
        } catch (error) {
            setLoading(false);
            setErrorMessage("Login failed. Please try again.");
        }
    };

    const logout = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND}/user/logout/`);
            window.location.reload();  // To reload after logging out
        } catch (error) {
            console.log("Error logging out");
        }
    };

    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/user/profile`);
            const data = response.data;
            if (data?.success) {
                setLogged(true);
                setLoggedUser({
                    username: `${data.data.firstName} ${data.data.lastName}`,
                    avatar: data.data.profilePicture,
                });
            } else {
                setLogged(false);
            }
        } catch (error) {
            setLogged(false);
        }
    };

    useEffect(() => {
        fetchProfile();
        document.title = "Login | Aakhyaan Academy";  // Setting document title
    }, []);

    return (
        <div className="h-screen">
            {logged ? (
                <div className="bg-back dark:bg-background w-full h-full flex items-center justify-center gap-20 pl-10 pr-10">
                    <div className="flex flex-col gap-3 items-center">
                        <div className="dark:text-red-400 font-sans text-red-800">
                            Hey, you are already logged in as
                        </div>
                        <div className="dark:bg-formBox dark:text-mainText/75 md:bg-blue-200 flex flex-col gap-3 dark:md:border-0 md:border-2 shadow-lg p-10">
                            <div className="hover:cursor-pointer flex flex-col md:flex-row items-center gap-4 md:gap-10">
                                <div
                                    onClick={() => navigate("/")}
                                    className="flex items-center gap-3"
                                >
                                    <Avatar alt="" src={loggedUser?.avatar} />
                                    <div className="font-sans dark:text-mainText/75 text-blue-900 text-xl hover:underline">
                                        {loggedUser?.username}
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <span onClick={logout} className="font-sans hover:underline">
                                        <Link to="/">Log Out</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="bg-back dark:bg-background w-full h-full flex items-center justify-center gap-20 px-10">
                    <div className="animate-moveRight select-none hidden lg:flex w-[550px] h-[500px] relative">
                        <img src="/teaching1.svg" alt="sign up" className="object-contain" />
                    </div>
                    <div className="animate-jump w-full max-w-xs md:max-w-md flex flex-col gap-10 items-center">
                        <form
                            className="dark:md:bg-formBox md:bg-blue-200 md:shadow-md rounded md:px-12 py-8 mb-4"
                            onSubmit={handleSubmit}
                        >
                            <div className="mb-4">
                                <label className="dark:text-white block text-gray-700 font-sans text-lg mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    className="font-sans shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-blue-600"
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    value={user.email}
                                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="mb-4 relative">
                                <label className="dark:text-white block font-sans text-lg text-gray-700 mb-2" htmlFor="password">
                                    Password
                                </label>
                                <input
                                    className="font-sans shadow border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-blue-800"
                                    id="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={user.password}
                                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                                    required
                                />
                                <span className="hover:cursor-pointer absolute right-1 bottom-[18px]">
                                    {showPassword ? (
                                        <BiShow onClick={() => setShowPassword(false)} size={25} />
                                    ) : (
                                        <BiHide onClick={() => setShowPassword(true)} size={25} />
                                    )}
                                </span>
                            </div>
                            <div className="mb-4 font-sans tracking-wide text-center text-red-500">
                                {errorMessage}
                            </div>
                            <div className="flex flex-col gap-5 md:gap-7">
                                <div className="flex gap-8 items-center justify-center md:justify-start">
                                    <button
                                        disabled={loading}
                                        className={`font-sans text-xl font-semibold transition-all ${loading ? "" : "hover:bg-blue-700"} bg-blue-500 text-white py-2 px-4 rounded focus:outline-none`}
                                        type="submit"
                                    >
                                        Login
                                    </button>
                                    <CircleLoader color="blue" loading={loading} size={40} />
                                </div>
                                <div className="flex flex-col md:flex-row gap-4 md:gap-2 items-center">
                                    <div className="font-sans text-lg text-slate-500 text-center">
                                        Haven't got an account?
                                    </div>
                                    <span className="font-sans text-xl hover:underline transition-all text-blue-600">
                                        <Link to="/signup">Sign Up</Link>
                                    </span>
                                </div>
                                <span className="dark:hover:text-blue-400 text-center md:text-left font-sans inline-block align-baseline text-lg text-blue-500 hover:text-blue-800">
                                    <Link to="/forgot-password">Forgot Password?</Link>
                                </span>
                            </div>
                        </form>
                        <p className="dark:text-white text-center text-gray-500 text-base">
                            Aakhyaan © 2022 aakhyaan.org. All Rights Reserved
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Login;
