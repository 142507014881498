
import { api } from '../service/axios'

export const getMcqs = (url) => api.get(url, { withCredentials: true }).then(res => res.data)
export const editMcq = (url, data) => api.post(url, data, { withCredentials: true }).then(res => res.data)
export const mcqEnable = (url, data) => api.post(url, data, { withCredentials: true }).then(res => res.data)
export const createMcq = (url, data) => api.post(url, data, { withCredentials: true }).then(res => res.data)
export const delMcq = (url, data) => api.post(url, data, { withCredentials: true }).then(res => res.data)
export const importMcq = (url, data) => api.post(url, data, { withCredentials: true }).then(res => res.data)

export const getMcqQuestions = (url) => api.get(url, { withCredentials: true }).then(res => res.data)
export const appendQuestion = (url, data) => api.post(url, data, { withCredentials: true }).then(res => res.data)
export const delQuestion = (url, data) => api.post(url, data, { withCredentials: true }).then(res => res.data)

export const getResults = (url) => api.get(url, { withCredentials: true }).then(res => res.data)