import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  // withCredentials: true, // Ensure cookies are included
});

api.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem("token");
    console.log(token,'token from interceptors');

    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle errors before the request is sent
    return Promise.reject(error);
  }
);
