import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import McqClient from "./pages/McqClient";
import { MathJaxContext } from "better-react-mathjax";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login";

const Admin = lazy(() => import("./pages/Admin"));

function App() {

  const config = {
    "fast-preview": {
      disabled: true,
    },
    tex2jax: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
    messageStyle: "none",
  };


  return (
    <div className="w-full h-screen font-sans">
      <MathJaxContext
        version={2}
        config={config}
        onStartup={(mathJax) => (mathJax.Hub.processSectionDelay = 0)}
      >
        <Suspense
          fallback={
            <div className="flex flex-col text-white bg-background w-screen h-screen relative">
              <div className="m-auto">Loading...</div>
            </div>
          }
        >
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl
            theme="colored"
          />

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Admin />} />
            <Route path="/mcqs/view/:id" element={<McqClient />} />
            <Route path="/mcqs/view/:chapterId/:id" element={<McqClient />} />
          </Routes>
        </Suspense>
      </MathJaxContext>
    </div>
  );
}

export default App;
